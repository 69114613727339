<template>
  <!-- <img  :src="logoSrc" alt="Vue logo" > -->
  <Header v-if="isWorldCup || isUefa"></Header>
  <HeaderEPL v-if="!isWorldCup && !isUefa"></HeaderEPL>
  <router-view :key="refresh"></router-view>
  <!-- <Footer></Footer> -->
  <FooterEPL v-if="!isWorldCup && !isUefa"></FooterEPL>
  <!-- <Toastr ref="toastrRef" :text="toastrMsg" :type="toastrType" :position="toastrPosition"></Toastr>  -->
</template>

<script>
// @ is an alias to /src
import Toastr from "@/components/toastr/Toastr.vue";
import config from "@/js/config.js";
import { mapGetters, mapActions } from "vuex";
import Header from "@/components/header/Header.vue";
import HeaderEPL from "@/components/header/HeaderEPL.vue";
// import Footer from "@/components/footer/Footer.vue";
import FooterEPL from "@/components/footer/FooterEPL.vue";

export default {
  name: "Home",
  components: {
    Header,
    HeaderEPL,
    // Footer,
    FooterEPL,
  },
  beforeMount() {
    this.checkIfWorldCup();
    this.checkIfUefa();
  },
  data() {
    return {
      logoSrc: require("../assets/logo.png"),
      toastrMsg: "",
    };
  },
  computed: {
    ...mapGetters(["refresh", "isWorldCup", "isUefa"]),
  },
  methods: {
    ...mapActions(["checkIfIsWorldCup", "checkIfIsUefa"]),
    checkIfWorldCup() {
      let result =
        this.$route.params.pathMatch === config.iframePaths.worldCup
          ? true
          : false;
      this.checkIfIsWorldCup(result);
    },
    checkIfUefa() {
      // uefa - 67
      let result = parseInt(this.$route.params.leagueId) == 67 ? true : false;
      this.checkIfIsUefa(result);
    },
  },
};
</script>
<style scoped></style>
