<template>
 <teleport to="body">

    <div class="toastr-wrapper" :class="position" v-if="isShowToastr">
        <div class="toastr-container" @click="handleClose()" :class="{'active' : isShowToastrContent}">
            <img v-if="toastr.type !=='info'" class="toastr-icon" src="../../../static/images/icons/icon_toastr_selected.png">
            <span>{{$t(toastr.msg)}}</span>
        </div>
    </div>
 </teleport>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
    props:{
        text: {
            type: String,
            default : ''
        },
        // type: {
        //     type: String,
        //     default : ''
        // },
        // position: {
        //     type: String,
        //     default: 'bottom' ,
        // }
    },
     watch:{
        toastr: {
            deep: true,
            handler(news, prev) {
                if (news.isOpen) {
                    this.handleOpen(); 
                }
                 
            }
        },
   },
    data() {
        return {
            position: 'bottom',
            isShowToastr : false,
            isShowToastrContent : false,
            closeTime: 2 * 1000 //2s
        }
    },

    computed:{
        ...mapGetters([              
            'isMobile',
            'toastr'
       ]),
      },
      mounted() {
         this.initToastr(); 
      },
    methods: {
         ...mapActions([
            "toastrChangeEvent"
        ]),
        handleOpen(){
            
            this.isShowToastr = true;
            setTimeout(() => {
                this.isShowToastrContent = true;
            }, 100);   

            setTimeout(() => {
               this.handleClose()
            }, this.closeTime);
        },
        handleClose(){
            let params={
                isOpen:false
            }
             this.toastrChangeEvent(params);
             this.isShowToastrContent = false;
                setTimeout(() => {
               this.isShowToastr = false;
            }, 300);
        },
          initToastr() {
            if (this.isMobile) {
               this.position ='bottom';
           } else {
              
               this.position ='top';
           }
       },
    },
}
</script>

<style scoped>
.toastr-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    min-height: 12rem;
    z-index: 11;
    pointer-events: none;
}

.toastr-container{
    padding: .5rem .8rem;
    background-color: white;
    color: black;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    transform: translate(0, 10rem);

}
.toastr-wrapper.top{
    bottom: unset;
    top: 0;
}
.toastr-wrapper.top .toastr-container{
    transform: translate(0, -10rem)
}
.toastr-container.active {
    transform: translate(0, 0) !important;
}
.toastr-icon{
    margin-right: .8rem;
}
@media (max-width: 768px){
    .toastr-container {
        max-width: 66%;
    }
}
</style>