

import config from '@/js/config.js'
import router from '@/router'
import { isSSR } from "@/shared/misc";

import moment from 'moment'
import {mapGetters,mapActions} from 'vuex'


router.beforeEach((to, from, next) => {
    // for google analytics to send data whiel routing to each page.

     //gtag.js
     // gtag('config', GTAG_ID,{'page_path': to.path});

      //GTM
      if (!isSSR) {
          dataLayer.push({
            event: 'pageview',
            page: {
              path: to.path,
              title: 'Your custom title'
            }
          });
      }

      next();
    })

export default{
    data() {
        return {
            isHomePage:null
        }
    },
    watch: {
        $route(to,from) {
            this.setCurrentLanguage(to, from);

            this.footerHandler(to);
            this.headerHandler(to);

            //to solve only mobile home page header different with other header
            if (to.meta.title === "home") { 
                this.isHomePage = true; 
            } else {
                this.isHomePage = false;
            }
            
            this.changeTitle(to, from);
            this.verifyToShowHeader(this.isHomePage);

             //fix AS-627 bug (fasterway to solve)
            //  setTimeout(() => {
            //     this.getContentListData();
            //   }, 500);
        },
        isLogin: {
            deep: true,
            handler(prev, nesw) {
                //debugger;
                debugger;
                // this.getContentListData();
            }
        },
      },
      
      beforeMount() {
        window.addEventListener('resize', this.resizeHandler)
      },
      computed:{
        ...mapGetters([              
            'isMobile',
            'footerTabs',
            'headerTabs',
            "userInfo",
            'currentLocale'
       ]),
      },
      created() {
        //to solve only mobile home page header different with other header
        if (this.$route.meta.title === "home") { 
            this.isHomePage = true; 
        } else {
            this.isHomePage = false;
        }
        this.verifyToShowHeader(this.isHomePage);
      },
      mounted() {
         
        //initital to check whether device is in which mobile or desktop mode
        this.resizeHandler();

        //verify login status
        this.changeTitle();
        this.clearServiceWorkerCahce();
        this.setCurrentLanguage();
        this.initToastr();
        this.verifyLogin();
        this.footerHandler();
        this.headerHandler();
        // this.getContentListData();
            
      },

      methods: {
        ...mapActions([
            "deviceChangeSizeEvent",
            "isShowHeaderEvent",
            "currentLocaleChangeEvent",
            "isLoginEvent",
            "logoutEvent",
            "getContentList",
            "navigationMenuEvent"
        ]),
        
        setCurrentLanguage(to, from) {
           
            if (typeof to === "undefined") {
                let fullPath = window.location.pathname.split('/');
               
                let locale = fullPath[1] ;
                let currentLanguageObj;
                config.languageList.forEach(x => {
                    if (x.displayLocale === locale) {
                        currentLanguageObj = x;
                    }
                });
                // set multi language for i18n
                this.$i18n.locale = currentLanguageObj.displayLocale;

                //set multi language for moment.js
                moment.locale(currentLanguageObj.locale)  
                this.currentLocaleChangeEvent(currentLanguageObj.locale);
            } else {
                let toLocale = to.params.locale; 
                let fromLocale = from.params.locale; 
    
                if (toLocale !== fromLocale) {
                    let currentLanguageObj;
    
                    config.languageList.forEach(x => {
                        if (x.displayLocale === toLocale) {
                            currentLanguageObj = x;
                        }
                    });
    
                    // set multi language for i18n
                    this.$i18n.locale = currentLanguageObj.displayLocale;
    
                    //set multi language for moment.js
                    moment.locale(currentLanguageObj.locale)     
                    
                    // set currentLocale variable
                    this.currentLocaleChangeEvent(currentLanguageObj.locale);
                }
            }
           
        },
        globalHelper(){
            alert("Hello world")
          },
        isMobileChecking(e) {
            let deviceWidth = 0;
            if (typeof e === "undefined") {
                deviceWidth = window.innerWidth;
            } else {
                deviceWidth = e.currentTarget.innerWidth 
            }
       
            if (deviceWidth > config.mobileWidth) {           
                return false
            } else{
                return true
            }
        },

        resizeHandler(e) { 
            const isMobile = this.isMobileChecking(e);
            
            this.deviceChangeSizeEvent(isMobile);
            this.verifyToShowHeader();  
        },

        verifyToShowHeader() {
            //only show header for 
            //homepage desktop and mobile,
            //otherpage desktop
            let isHomePage = this.isHomePage;
            let isOtherPage = !isHomePage;
            let isDesktop = !this.isMobile;
            let isMobile = this.isMobile;

            let isShowHeader = true;
       
            if (isHomePage) {
                isShowHeader = true;
            } else if (isOtherPage && isDesktop) {
                isShowHeader = true;
            } else if (isOtherPage && isMobile) {
                isShowHeader = true;
                
            }  
            this.isShowHeaderEvent(isShowHeader);        
        },

        verifyLogin() {
            
            let localStorageUser = localStorage.getItem("user");
          
            //if local storage has user data mean status is Login
            if (localStorageUser !== null) {
                if (localStorageUser !== 'null') {
                    this.isLoginEvent();
                
                    // get user preferredLanguage and set it to website
                    let preferredLanguage = this.userInfo.preferredLanguage;
              
                    // set currentLocale variable
                    this.currentLocaleChangeEvent(preferredLanguage);
                }
            } else {
                this.logoutEvent();
            }
        },
        clearServiceWorkerCahce() {
            if (typeof caches !== "undefined") {
                caches.keys().then(function(names) {
                    for (let name of names)
                        caches.delete(name);
                });
            }
           
        },

        changeTitle(to, from) {
            if (typeof to === 'undefined') {
                to = this.$route
            }
            //debugger;
            let projectName = config.projectName;
            let title = projectName;
            let descriptionTitle = descriptionTitle;
            let tagsTitle = tagsTitle;
            const whiteList = {
                en: {
                    team: [
                        {
                            id: 59,
                            displayName: 'Leicester City'
                        },
                        {
                            id: 27,
                            displayName: 'Manchester United'  
                        },
                        {
                            id: 25,
                            displayName: 'Liverpool'  
                        },
                        {
                            id: 19,
                            displayName: 'Arsenal'  
                        },
                        {
                            id: 26,
                            displayName: 'Manchester City'  
                        }
                    ]
                },
                th: {
                    team: [
                        {
                            id: 27,
                            displayName: 'สโมสร แมนฯ ยูไนเต็ด '
                        },
                        {
                            id: 25,
                            displayName: 'สโมสร ลิเวอร์พูล '
                        },
                        {
                            id: 19,
                            displayName: 'สโมสร อาร์เซน่อล '
                        },
                        {
                            id: 26,
                            displayName: 'สโมสร แมนฯ ซิตี้'
                        },
                        {
                            id: 59,
                            displayName: 'สโมสร เลสเตอร์ซิตี้'
                        },
                        {
                            id: 4028,
                            displayName: 'บุรีรัมย์ยูไนเต็ด '
                        },
                        {
                            id: 9960,
                            displayName: 'บีจีปทุม ยูไนเต็ด '
                        },
                        {
                            id: 5211,
                            displayName: 'แบงค็อก ยูไนเต็ด '
                        },
                        {
                            id: 9946,
                            displayName: 'เมืองทอง ยูไนเต็ด '
                        }
                    ]
                },
                vn: {
                    team: [
                        {
                            id: 2026,
                            displayName: 'Hoàng Anh Gia Lai',
                            description: 'câu lạc bộ Hoàng Anh Gia Lai',
                            tags: 'Hoàng Anh Gia Lai, CLB Hoàng Anh Gia Lai, câu lạc bộ Hoàng Anh Gia Lai, lịch thi đấu HAGL, đội hình HAGL, soi kèo HAGL'
                        },
                        {
                            id: 24875,
                            displayName: 'Viettel',
                            description: 'Viettel Club',
                            tags: 'Câu lạc bộ Viettel, Viettel Club, lịch thi đấu Viettel, soi kèo Viettel club'
                        },
                        {
                            id: 20032,
                            displayName: 'Than Quảng Ninh',
                            description: 'Than Quảng Ninh',
                            tags: 'TThan Quảng Ninh, câu lạc bộ Than Quảng Ninh, đội hình Than Quảng Ninh, soi kèo Than Quảng Ninh'
                        },
                        {
                            id: 7241,
                            displayName: 'Nam Định',
                            description: 'CLB Nam Định',
                            tags: 'clb Nam Định, Câu lạc bộ Nam Định, đội hình Nam Định, soi kèo Nam Định'
                        }
                    ]
                }
            } 
            const teamTitleList = {
                en: " Football Club, Lineups, Fixtures, News",
                th: "รายชื่อผู้เล่น โปรแกรมแข่งขัน ข่าว",
                vn: ": Đội hình, Lịch thi đấu, Tin tức",            
            } 
            const descriptionList = {
                vn: "Cập nhật kết quả bóng đá trực tiếp",            
            } 
            const descriptionListEnd = {
                vn: "nhanh nhất, bảng xếp hạng V League và tin tức, soi kèo bóng đá bởi các chuyên gia săn tin hàng đầu tại",            
            }  
            if (to.params.locale === 'en') {
                if (to.fullPath === '/en' || to.fullPath === '/en/') {
                    document.title = `Live Football Results, Live Scores, Fixtures, Video Highlights - ${projectName}`
                }else if (to.fullPath === '/en/football/english-premier-league/english-premier-league-36?leagueId=36' || to.fullPath === '/en/football/english-premier-league/english-premier-league-36?leagueId=36/') {
                    document.title = `English Premier League, EPL Table, Fixtures, Highlights - ${projectName}`
                } else {
                    document.title = projectName
                }           
            } else if (to.params.locale === 'th') {
                if (to.fullPath === '/th' || to.fullPath === '/th/') {
                    document.title = `วิเคราะห์บอลวันนี้ ผลบอลสด โปรแกรมแข่งขัน ข่าวกีฬา ไฮไลท์- ${projectName}`
                } else if(to.fullPath === '/th/football/english-premier-league/english-premier-league-36?leagueId=36' || to.fullPath === '/th/football/english-premier-league/english-premier-league-36?leagueId=36/'){
                    document.title = `พรีเมียร์ลีกอังกฤษ, ตารางคะแนน, โปรแกรมแข่งขัน, ไฮไลท - ${projectName}`
                } else if(to.fullPath === '/th/football/thailand/thai-premier-league-700?leagueId=700' || to.fullPath === '/th/football/thailand/thai-premier-league-700?leagueId=700/'){
                    document.title = `ไทยลีก1 โปรแกรมแข่งขัน ตารางคะแนน ไฮไลท - ${projectName}`
                } else {
                    document.title = projectName
                }           
            } else if (to.params.locale === 'vn') {
                if (to.fullPath === '/vn' || to.fullPath === '/vn/') {
                    document.title = `Kết quả bóng đá, tỷ số trực tiếp, lịch thi đấu, video bóng đá - ${projectName}`,
                    document.getElementsByName('description')[0].content=`Xem kết quả bóng đá đang diễn ra Premier League, UEFA Champions League, La Liga, Bundesliga, Serie A và các câu lạc bộ bạn yêu thích tại ${projectName}!`
                    document.getElementsByName('tags')[0].content=`Kết quả bóng đá, tỷ số trực tiếp, lịch thi đấu, video bóng đá`
                } else if (to.fullPath === '/vn/football/vietnam/vietnamese-professional-league-766?leagueId=766' || to.fullPath === '/vn/football/vietnam/vietnamese-professional-league-766?leagueId=766/') {
                    document.title = `V League 1: Bảng xếp hạng, Lịch thi đấu, Soi kèo - ${projectName}`,
                    document.getElementsByName('description')[0].content=`Cập nhật kết quả bóng đá trực tiếp V League nhanh nhất, bảng xếp hạng V League và tin tức, soi kèo bóng đá bởi các chuyên gia săn tin hàng đầu tại ${projectName}!`
                    document.getElementsByName('tags')[0].content=`V League, bảng xếp hạng, lịch thi đấu V League, Soi kèo V League`
                } else {
                    document.title = projectName
                }           
            } else {
                document.title = projectName
            }
            // team overview
            if (to.name === 'teamOverview') {                   
                let team = whiteList[to.params.locale].team;
                for(let i = 0; i < team.length; i++) {
                    if (team[i].id ===  parseInt(to.params.teamKey)) {
                        title = `${team[i].displayName} ${teamTitleList[to.params.locale]} - ${projectName}`;
                        document.title = title;
                        descriptionTitle = `${descriptionList[to.params.locale]} ${team[i].description} ${descriptionListEnd[to.params.locale]} ${projectName}`;
                        document.getElementsByName('description')[0].content= descriptionTitle;
                        tagsTitle = `${team[i].tags}`;
                        document.getElementsByName('tags')[0].content= tagsTitle;
                    }
                }
            }
        },

        footerHandler() {
            let e = this.$route;
            for (let i=0; i<this.footerTabs.length; i++) {    
                if (this.footerTabs[i].id === e.meta.id) {
                    this.footerTabs[i].selected = true; 
                } else {
                    this.footerTabs[i].selected = false;
                }
            }                     
        },

        headerHandler() {
            let e = this.$route;
            for (let i=0; i<this.headerTabs.length; i++) {    
                if (this.headerTabs[i].id === e.meta.id) {
                    this.headerTabs[i].selected = true; 
                } else {
                    this.headerTabs[i].selected = false;
                }
            }                     
        },

        initToastr() {
            if (this.isMobile) {
               this.$parent.toastrPosition ='bottom';
           } else {
              
               this.$parent.toastrPosition ='top';
           }
       },

       
       async getContentListData() {
            let params = {
                timeType: 2,
                language: this.currentLocale
            }
        
            const result = await this.getContentList(params);

            let returnResult = result.result;

            this.navigationMenuEvent(returnResult);
        },

      },
}