import Vue from 'vue'
import axios from 'axios'
import config from '@/js/config'

axios.defaults.withCredentials = true
axios.defaults.timeout = 15000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'; 
axios.defaults.baseURL = config.apiUrl;  
axios.interceptors.response.use(function (response) {
    if (response.data.result !== null) {
        if (response.data.result.businessCode === 100007) {
            // request timeout, user has been blocked, then remover user in localstorage reload whole page
            localStorage.removeItem('user');
            location.reload();
        }
    }
  

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
  
    //    this.$root.toastrMsg ='WELCOME_BACK';
    //    this.$root.$refs.toastrRef.handleOpen(); 
   
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });
export default {
    get(url,param){
        //change goal serve api to 
        let urlArray = url.split("/");
        let urlArrayLength = urlArray.length;
        let apiVersion = urlArray[1];
        let newUrl = urlArray.slice(2,urlArrayLength).join("/")
      
        if (typeof apiVersion !== 'undefined' && apiVersion === "v2") {
            url = '/' + newUrl
            axios.defaults.baseURL = config.apiUrlV2
        } else {
            axios.defaults.baseURL = config.apiUrl;  
        }
        
        return new Promise((resolve, reject) => {
            axios.get(url, {params:param})
            .then(response => {
                //console.log(response.data)
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.data)
            })
        })
    },
    post(url,param){
        //change goal serve api to 
        let urlArray = url.split("/");
        let urlArrayLength = urlArray.length;
        let apiVersion = urlArray[1];
        let apiUrl = '';
        let newUrl = urlArray.slice(2,urlArrayLength).join("/")

        if (typeof apiVersion !== 'undefined' && apiVersion === "v2") {
            url = '/' + newUrl
            apiUrl = config.apiUrlV2
        } else {
            apiUrl = config.apiUrl;  
        }
        
        return new Promise((resolve, reject) => {

            $.ajax({
                url: `${apiUrl}${url}`,//`${config.apiUrl}${url}`,
                type: "POST",
                data: param,
                success: function(data) {
                    if (data.result !== null) {
                        if (data.result.businessCode === 100007) {
                            // request timeout, user has been blocked, then remover user in localstorage reload whole page
                            localStorage.removeItem('user');
                            window.location.href = `${window.location.protocol}//${window.location.host}/${location.pathname.split("/")[1]}`;        
                        }
                    }
                  
                    resolve(data);
                },
                error: function(data) {
                    reject(data);
                }
            });
            // axios.post(url, {
            //     method: 'PHONE', //now we only have phone signup. method, i.e.: PHONE, EMAIL
            //     username: '60197185624'
            // })
            // .then(response => {
            //     //console.log(response.data)
            //     resolve(response.data);
            // }, err => {
            //     reject(err.data);
            // })
            // .catch((error) => {
            //     reject(error.data)
            // })
        })
    },
    getSelfUrl(url,param){
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${url}`,
                type: "GET",
                data: param,
                success: function(data) {
                 
                    resolve(data);
                },
                error: function(data) {
                    reject(data);
                }
            });
         
        })
    },
}
