<template>
  <!-- <div :class="{'icon-player': category==='player', hasClass: hasClass !==''}"> -->
    <div v-if="category !=='player'" :class="hasClass">
      <img v-if="id !==undefined" :src="`${s3ImgUrl}/${category}/${id}.png`" class="img-contain w-100 h-100" >
      <img v-if="logoUrl !==undefined" :src="`${s3ImgUrl}/${logoUrl}`" class="img-contain w-100 h-100" >
      <!-- <img v-else :src="`${s3ImgUrl}/${logoUrl}`" class="img-contain w-100 h-100" > -->
   </div>

   <div v-else class="icon-player">
        <img :src="`${s3ImgUrl}/${logoUrl}`" class="img-contain w-100 h-100" >
   </div>
</template>

<script>
import config from '@/js/config.js'
export default {
    props: {
        category: {
            type: String
        },
        id: {
            type: String 
        },
        hasClass:{
            type: String,
            default: 'icon-league' 
        },
        logoUrl: {
            type: String,
        }
    },
    data() {
        return {
            s3ImgUrl:config.s3ImgUrl
        }
    },
    methods: {
       replaceByDefault(e) {        
         e.target.src = `${this.s3ImgUrl}/${this.category}/default.png`
        },
    },
}
</script>

<style>
.icon-league{
    width: 1.5rem;
    height: 1.5rem;
}
.icon-league img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.icon-player{
    width: 1.5rem;
    height: 2rem;
}
.icon-player img{
    object-fit: cover;
}
</style>