import { isSSR } from "@/shared/misc";
import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
  RouteRecordRaw
} from "vue-router";
import Home from '../views/Home.vue'
import config from '@/js/config.js'
// meta:
// id:1 matches
// id:2 highlight
// id:3 news
// id:4 event
let defaultLocale = config.defaultLocale;
let worldCup = config.iframePaths.worldCup;
let leagues = config.iframePaths.leagues;
let uefa = config.iframePaths.uefa;

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect:`/${defaultLocale}`,
  },
  {
    path: '/:locale/signup',     
    meta: { id: 4 },
    name: 'signup',
    component: () => import('../views/user/SignUp.vue')
  },
  {
    path: '/:locale/signin',     
    meta: { id: 4 },
    name: 'signin',
    component: () => import('../views/user/SignIn.vue')
  },
  {
    path: '/:locale/forgetpassword',     
    meta: { id: 4 },
    name: 'forgetpassword',
    component: () => import('../views/user/ForgetPassword.vue')
  },

  {
    path: '/:locale/account',  
    name: 'account',   
    meta: { parent: 'account' },
    component: () => import('../views/account/Account.vue'),
    // redirect:{ name: 'myProfile' },
  },
  {
    path: '/linecallback',  
    name: 'linecallback',   
    component: () => import('../views/callback/LineCallBack.vue'),
  },
  {
    path: '/zaloCallBack',  
    name: 'zaloCallBack',   
    component: () => import('../views/callback/ZaloCallBack.vue'),
  },
  {
    path: '/:locale/account/myProfile',   
    name: 'myProfile', 
    meta: {name: 'MY_PROFILE', parent: 'account'},
    component: () => import('../views/account/MyProfile.vue'),
  },

  {
    path: '/:locale/account/changePassword',   
    name: 'changePassword', 
    meta: {name: 'CHANGE_PASSWORD' , parent: 'account'},
    component: () => import('../views/account/ChangePassword.vue'),
  },
  {
    path: '/:locale/account/myPhoneNumber',   
    name: 'myPhoneNumber', 
    meta: {name: 'MY_PHONE_NUMBER', parent: 'account'},
    component: () => import('../views/account/MyPhoneNumber.vue'),
  },

  {
    path: '/:locale/account/myReferralCode',   
    name: 'myReferralCode', 
    meta: {name: 'MY_REFERRAL_CODE' , parent: 'account'},
    component: () => import('../views/account/MyReferralCode.vue'),
  },

  {
    path: '/:locale',
    component: () => import('../views/Home.vue'),
    redirect:`/${defaultLocale}`,
    children: [
      {
        path: 'football',
        component: () => import('../views/matchs/MatchsEPL.vue'),
        // component: () => import('../views/matchs/Matchs.vue'),
      },
      {
        path: '',
        component: () => import('../views/matchs/MatchsEPL.vue'),
        // component: () => import('../views/matchs/Matchs.vue'),
        name: 'home',
        meta: { id: 1, title: 'home' },
      },
      {
        path: `:pathMatch(${worldCup}|${leagues}|${uefa})/match/:leagueId`,//''
        component: () => import('../views/matchs/Matchs-WC-backup.vue'),
        name: 'matches',
        meta: { id: 1, title: 'home' },
      },
      {
        path: `:pathMatch(${worldCup}|${leagues}|${uefa})/standings/:leagueId`,
        component: () => import('../views/matchs/Standings-WC-backup.vue'),
        name: 'standings',
        meta: { id: 2, title: 'standings' },
      },
      {
        path: `:pathMatch(${worldCup}|${leagues}|${uefa})/bracket/:leagueId`,
        component: () => import('../views/matchs/Bracket.vue'),
        name: 'bracket',
        meta: { id: 3, title: 'bracket' },
      },
      {
        path: 'match2',  
        component: () => import('../views/matchs/Matchs2.vue'),
        name: 'home2',
        meta: { id: 1, title: 'home' },
      },
      {
        path: 'fav',      
        component: () => import('../views/fav/Fav.vue')
      },
	    {
        path: 'highlights',     
		    meta: { id: 2 },
        name: 'highlights',
        component: () => import('../views/highlights/Matchs.vue')
      },

      //news
      {
        path: 'news',     
		    meta: { id: 3 },
        name: 'news',
        component: () => import('../views/news/News.vue')
      },
      {
        path: 'news/categories/:categoryName/:categoryId',     
		    meta: { id: 3 },
        name: "newsCategories",
        component: () => import('../views/news/NewsCategories.vue')
      },
      {
        path: 'news/related/:categoryName/:categoryId',     
		    meta: { id: 3 },
        name: "newsRelated",
        component: () => import('../views/news/NewsCategories.vue')
      },
      {
        path: 'news/:currentSportType/:title/:id',     
		    meta: { id: 3 },
        name: "newsDetail",
        component: () => import('../views/news/NewsDetail.vue')
      },
      {
        path: 'news/author/:authorName/:id',     
		    meta: { id: 3 },
        name: "newsAuthor",
        component: () => import('../views/news/NewsAuthor.vue')
      },

      //event
      {
        path: 'event',     
		    meta: { id: 4 },
        name: "event",
   
        // query: {type: 'ongoing'},
        component: () => import('../views/event/Event.vue')
      },

      {
        path: 'event/prediction/:contestId',     
		    meta: { id: 4 },
        name: "eventPrediction",
        component: () => import('../views/event/Prediction.vue')
      },
      {
        path: 'event/matchscore/:contestId',     
		    meta: { id: 4 },
        name: "eventMatchScore",
        component: () => import('../views/event/MatchScore.vue')
      },
      {
        path: 'event/topreferral/:contestId',     
		    meta: { id: 4},
        name: "eventTopreferral",
        component: () => import('../views/event/Topreferral.vue')
      },
      {
        path: 'football/highlights/:country/:club/:opponent/:matchId',     
		    meta: { id: 2 },
        component: () => import('../views/highlights/HighlightsDetail.vue')
      },
      {
        path: 'football/:country/:leagueKey',   
	    	meta:{ id: 1 },   
        component: () => import('../views/matchs/League.vue'),
        children:[
          {
            path: 'matches',      
            component: () => import('../views/matchs/league/Matches.vue'),
          },
          {
            path: 'table',      
            component: () => import('../views/matchs/league/Table.vue'),
          },
          {
            path: 'about',      
            component: () => import('../views/matchs/league/About.vue'),
          },
        ]
      },
      {
        path: '/:locale/privacy-policy',     
        meta: { id: 4 },
        name: 'privacypolicy',
        component: () => import('../views/other/PrivacyPolicy.vue')
      },
      {
        path: 'football/:country/:club/:opponent/:matchId',      
        component: () => import('../views/matchs/matchDetail/Index.vue'),
		    meta:{ id: 1, category: 'match detail'},
		    redirect:{ name: 'matchDetailInfo' },
        children:[
          {
            path: 'info',   
			      name:'matchDetailInfo',   
            component: () => import('../views/matchs/matchDetail/Info.vue'),
          },
          {
            path: 'tracker',      
            component: () => import('../views/matchs/matchDetail/Tracker.vue'),
          },
          {
            path: 'stats',      
            component: () => import('../views/matchs/matchDetail/Stats.vue'),
            children: [
              {
                path: "odds",
                name: "oddsResult",
                component: () =>
                  import(
                    "../views/matchs/matchDetail/matchListing/OddsResult.vue"
                  ),
                children: [
                  {
                    path: "filter",
                    name: "oddsResultFilter",
                    component: () =>
                      import(
                        "../views/matchs/matchDetail/matchListing/OddsFilter.vue"
                      ),
                  },
                ],
              }
            ],
          },
          {
            path: 'lineups',      
            component: () => import('../views/matchs/matchDetail/LineUps.vue'),
          },
          {
            path: 'h2h',      
            component: () => import('../views/matchs/matchDetail/H2H.vue'),
          },
          {
            path: 'table',      
            component: () => import('../views/matchs/matchDetail/Table.vue'),
          },
        ]
      },
      {
        path: 'football/team/:teamName/:teamKey',      
        component: () => import('../views/matchs/team/Index.vue'),
        redirect:{ name: 'teamOverview' },
		    meta:{ id: 1 } ,
        children:[
          {
            path: 'overview',      
            name:'teamOverview',
            component: () => import('../views/matchs/team/Overview.vue'),
          },
          {
            path: 'match',  
            name:'teamMatch',    
            component: () => import('../views/matchs/team/Match.vue'),
          }, 
          {
            path: 'squad',
            name:'teamSquad',         
            component: () => import('../views/matchs/team/Squad.vue'),
          },  
          {
            path: 'about',
            name:'teamAbout',         
            component: () => import('../views/matchs/team/About.vue'),
          },     
        ]
      },
      {
        path: 'football/player/:playerName/:player',  
		    meta:{ id:1 } ,
        component: () => import('../views/matchs/player/Index.vue'),  
		    redirect:{ name: 'playerOverview' },
        children:[
          {
            path: 'overview',    
			      name:'playerOverview',  
            component: () => import('../views/matchs/player/Overview.vue'),
          },
          {
            path: 'career',  
			      name:'playerCarrer',     
            component: () => import('../views/matchs/player/Career.vue'),
          },
          {
            path: 'transfer',   
			      name:'playerTransfer',    
            component: () => import('../views/matchs/player/Transfer.vue'),
          },
      ]
      },
    ]
  },
  
]


const router = createRouter({

  history: isSSR ? createMemoryHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),//createWebHistory(process.env.BASE_URL)
  routes,
  scrollBehavior(to, from, savedPosition) {
   
    if (savedPosition) {
      return savedPosition;
    } else {    
          if( from.meta.category === "match detail" && to.meta.category === "match detail") { //desktop version match detail when switch tab save window previous position
            return savedPosition;    
          } else {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 60)
          }
        }
  }
})

export default router
