<template>
     <router-link  :to="{path:`/${currentLocale}/${currentSportType}/${category}/${name}/${id}`}" v-if=" id !== null">
        <slot></slot>
    </router-link>
     <div v-else>
        <slot></slot>
     </div>

</template>

<script>
import {mapGetters,mapActions} from 'vuex'

export default {
    
    props:{
        id:{
            type : String
        },
        category : {
            type : String,            
        },
        name : {
            type : String,            
        }
    },

    data() {
        return {
            link:''
        }
    },
    computed:{
        ...mapGetters([              
            'isMobile',
            'currentSportType',
            'currentLocale'
        ]),
        name(){
            let filteredName = this.name;

            if (typeof filteredName !== 'undefined') {
                filteredName = this.name.replace(/\s/g, '-');
                filteredName = filteredName.replace(/\?/g,'');
               filteredName = filteredName.replace(/\//g, '-');           

            } 
           return filteredName;
        }
    },
  


}
</script>

<style>

</style>