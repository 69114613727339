module.exports = {
    apiUrl: process.env.VUE_APP_SERVICE_URL,
    apiUrlV2: process.env.VUE_APP_SERVICE_URL_V2,
    s3ImgUrl: process.env.VUE_APP_S3_IMG_URL,

    recaptchaSiteKey:"6LcA064fAAAAAMzGxzBK8wNpwbd9UgN2yxU7gB5E",
    projectName: 'AsiaSport',
    mobileWidth : 765,

    defaultLocale: 'th',//'en

    displayDateFormat: "DD MMM YYYY",
    apiParamDateFormat:  "YYYY-MM-DD",

    intervalMatchList: 5000, //5s getMatchListData apply to home page,  league page ,  team info - match page

    pageSize: 500,

    socialMediaShareList: [
        {
            id: "fb",
            name: "SHARE_ON_Facebook",
            socialMediaUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
            iconUrl:'icon_fb'
        },
        {    
            id: "twitter",
            name: "SHARE_ON_Twitter",
            socialMediaUrl: 'https://twitter.com/share?url=',
            iconUrl:'icon_twitter'
        },
        {    
            id: "line",
            name: "SHARE_ON_Line",
            socialMediaUrl: 'https://social-plugins.line.me/lineit/share?url=',
            iconUrl:'icon_line'
        },
        {    
            id: "selfShare",
            name: "COPY_LINK",
            socialMediaUrl: "",
            iconUrl:'icon_share'
        }
    ],

    languageList: [
        {
            country: 'Thailand',
            displayName: 'ภาษาไทย',
            locale: 'th',
            displayLocale: 'th',
            selected: false,
            active: true,
            icon: 'thailand'
        },
        {
            country: 'England',
            displayName: 'English',
            locale: 'en',
            displayLocale: 'en',
            selected: true,
            active: true,
            icon: 'england'
        },
        // {
        //     country: 'Vietnam',
        //     displayName: 'Tiếng Việt',
        //     locale: 'vi',
        //     displayLocale: 'vn',
        //     selected: false,
        //     active: true,
        //     icon: 'vietnam'
        // }
    ],

    countryList:[
        {
            country: 'Thailand',
            name: 'Thailand',
            icon: 'thailand',
            callingCode: 66,
            timeZone: 'Asia/Bangkok',
            selected: false,
        },
        {
            country: 'Malaysia',
            name: 'Malaysia',
            icon: 'malaysia',
            callingCode: 60,
            timeZone: 'Asia/Kuala_Lumpur',
            selected: false,
        },
        {
            country: 'Singapore',
            name: 'Singapore',
            icon: 'singapore',
            callingCode: 65,
            timeZone: 'Asia/Singapore',
            selected: false,
        }
    ],

    lineChannelId: "1656581900",

    pageSizeComment: 10,
    pageSizeChildComment: 100,

    app: {
        android:  {
            link:'https://play.google.com/store/apps/details?id=com.asiasport.asia'
        },
        apple: {
            link:'https://apps.apple.com/my/app/asiasport/id1610463486'
        }
    },

    officialASUrl: "https://www.asiasport.com",

    iframePaths: {
        worldCup: 'worldcup',
        leagues: 'leagues',
        uefa: 'euro2024',
    },
    worldCupStartDate: "2022-11-20",
    worldCupEndDate: "2022-12-18",    
    worldCupSubLeagueList: [
        {
            subLeagueName: 'All Stages',
            date: '',
            qatarDate: '',
            isLiveOnly: false,
            selected: false
        },
        {
            subLeagueName: 'Matchday 1 of 3',
            date: '',
            qatarDate: '2022-11-20T19:00:00.000+03:00',
            isLiveOnly: false,
            selected: false
        },
        {
            subLeagueName: 'Matchday 2 of 3',
            date: '',
            qatarDate: '2022-11-25T13:00:00.000+03:00',
            isLiveOnly: false,
            selected: false
        },
        {
            subLeagueName: 'Matchday 3 of 3',
            date: '',
            qatarDate: '2022-11-29T18:00:00.000+03:00',
            isLiveOnly: false,
            selected: false
        },
        {
            subLeagueName: 'Round of 16',
            date: '',
            qatarDate: '2022-12-03T18:00:00.000+03:00',
            isLiveOnly: false,
            selected: false
        },
        {
            subLeagueName: 'Quarter-finals',
            date: '',
            qatarDate: '2022-12-09T18:00:00.000+03:00',
            isLiveOnly: false,
            selected: false
        },
        {
            subLeagueName: 'Semi-finals',
            date: '',
            qatarDate: '2022-12-13T22:00:00.000+03:00',
            isLiveOnly: false,
            selected: false
        },
        {
            subLeagueName: 'Third place play-off',
            date: '',
            qatarDate: '2022-12-17T18:00:00.000+03:00',
            isLiveOnly: false,
            selected: false
        },
        {
            subLeagueName: 'Final',
            date: '',
            qatarDate: '2022-12-18T18:00:00.000+03:00',
            isLiveOnly: false,
            selected: false
        },
    ],
    uefa2024StartDate: "2024-06-15",
    uefa2024EndDate: "2024-07-15",

    pageType: {
        ranking: "ranking",
        league: "league",
        team: "team",
    },
}