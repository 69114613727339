import { createStore } from "vuex";
import api from "@/js/api";
import config from "@/js/config.js";
import router from "@/router";
import moment from "moment";

const USER = "user";
const API_VERSION = "v2";
export default createStore({
  state: {
    refresh: false, //mobile footer refresh
    isRedirect: false,
    footerTabs: [
      {
        path: `/`,
        name: "HOME",
        pathName: "home",
        id: 1,
        isLive: false,
        selected: false,
      },
      {
        path: `/highlights`,
        name: "HIGHLIGHTS",
        pathName: "highlights",
        id: 2,
        isLive: false,
        selected: false,
      },
      {
        path: `/news`,
        name: "NEWS",
        pathName: "news",
        id: 3,
        isLive: false,
        selected: false,
      },
      {
        path: `/event?type=ongoing`,
        name: "EVENTS",
        pathName: "event",
        id: 4,
        isLive: true,
        selected: false,
      },
    ],
    eplFooterTabs: [
      {
        path: `/`,
        name: "HOME",
        pathName: "home",
        external: false,
        id: 1,
        selected: true,
      },
      {
        path: `/`,
        name: "HIGHLIGHTS",
        link: "https://www.asiasport.com/en/highlights",
        external: true,
        id: 2,
        selected: false,
      },
      {
        path: `/`,
        name: "NEWS",
        link: "https://www.asiasport.com/en/news",
        external: true,
        id: 3,
        selected: false,
      },
      {
        path: `/`,
        name: "FANTASY_SPORT",
        link: "https://www.asiasport.com/en/matches",
        external: true,
        id: 4,
        selected: false,
      },
    ],
    iframeHeaderTabs: [
      {
        name: "MATCHES",
        pathName: "matches",
        id: 1,
        selected: false,
      },
      {
        name: "STANDINGS",
        pathName: "standings",
        id: 2,
        selected: false,
      },
      {
        name: "BRACKET",
        pathName: "bracket",
        id: 3,
        selected: false,
      },
    ],
    eplHeaderTabs: [
      {
        path: `/`,
        name: "HOME",
        pathName: "home",
        external: false,
        id: 1,
        selected: true,
      },
      {
        path: `/`,
        name: "HIGHLIGHTS",
        link: "https://www.asiasport.com/en/highlights",
        external: true,
        id: 2,
        selected: false,
      },
      {
        path: `/`,
        name: "NEWS",
        link: "https://www.asiasport.com/en/news",
        external: true,
        id: 3,
        selected: false,
      },
      {
        path: `/`,
        name: "FANTASY_SPORT",
        link: "https://www.asiasport.com/en/matches",
        external: true,
        id: 4,
        selected: false,
      },
    ],
    headerTabs: [
      // {
      //   path: `/`,
      //   name: "MATCHES",
      //   pathName: "home",
      //   id: 1,
      //   isLive: false,
      //   selected: false,
      // },
      // {
      //   path: `/highlights`,
      //   name: "HIGHLIGHTS",
      //   pathName: "highlights",
      //   id: 2,
      //   isLive: false,
      //   selected: false,
      // },
      // {
      //   path: `/news`,
      //   name: "NEWS",
      //   pathName: "news",
      //   id: 3,
      //   isLive: false,
      //   selected: false,
      // },
      // {
      //   path: `/event?type=ongoing`,
      //   name: "EVENTS",
      //   pathName: "event",
      //   id: 4,
      //   isLive: true,
      //   selected: false,
      // },
    ],
    componentKey: false,
    leagueIdListRanking: [],

    homePageLeagueParams: {
      leagueIdList: [],
      date: "",
    },

    matchList: [],
    matchDetailScore: {},
    matchDetailInfo: {},
    isStopTimmer: false,
    matchId: "",
    // matchListing
    isOddsMore: false,
    isOddsMenu: false,
    homeOdds: {
      oddsOptions: {
        company: "",
        duration: "",
      },
      handicapOptions: {
        company: "",
        duration: "",
      },
      matchOptions: "",
      isSameLeague: false,
      leagueList: [],
    },
    awayOdds: {
      oddsOptions: {
        company: "",
        duration: "",
      },
      handicapOptions: {
        company: "",
        duration: "",
      },
      matchOptions: "",
      isSameLeague: false,
      leagueList: [],
    },
    h2hOdds: {
      oddsOptions: {
        company: "",
        duration: "",
      },
      handicapOptions: {
        company: "",
        duration: "",
      },
      matchOptions: "",
      isSameLeague: false,
      leagueList: [],
    },
    // matchListing End
    currentSportType: "football",
    currentLocale: "en",
    currentLanguageObj: config.languageList[0],
    isMobile: true,
    isShowHeader: false,
    isShowAppDownloadHome: true,

    isLogin: false,
    userInfo: {},

    teamInfo: {
      sidelined: {
        player: [],
      },
      transfers: {
        in: {
          player: [],
        },
        out: {
          player: [],
        },
      },
    },
    playerInfo: {},

    commentList: [],
    commentListInfo: {
      total: 0,
    },

    // pop signin variable
    popUpSignInModule: "signIn",
    toastr: {
      isOpen: false,
      type: "",
      msg: "",
    },

    isWorldCup: false,
    isUefa: false,
    listingHasLiveStream: false,
    defaultVideoToPlay: {
      matchId: null,
      liveStreamList: [],
    },
    matchListDataInterval: false,
  },
  mutations: {
    // matchListing
    MATCHODDSMORE: (state, data) => {
      if (data) {
        state.isOddsMore = data;
      } else state.isOddsMore = !state.isOddsMore;
    },
    MATCHODDSMENU: (state, data) => {
      if (data) {
        state.isOddsMenu = data;
      } else state.isOddsMenu = !state.isOddsMenu;
    },
    MATCHODDS_OPTION_HANDLER: (state, data) => {
      if (data && data.attr) {
        state[`${data.side}Odds`][data.table][data.attr] = data.data;
      } else state[`${data.side}Odds`][data.table] = data.data;
    },
    MATCHODDS_ISSAME_HANDLER: (state, data) => {
      state[`${data}Odds`]["isSameLeague"] = !state[`${data}Odds`][
        "isSameLeague"
      ];
    },
    MATCHODDS_LEAGUE_HANDLER: (state, data) => {
      if (!state[`${data.side}Odds`]["leagueList"].includes(data.data)) {
        state[`${data.side}Odds`]["leagueList"].push(data.data);
      } else
        state[`${data.side}Odds`]["leagueList"] = state[`${data.side}Odds`][
          "leagueList"
        ].filter((l) => l !== data.data);
    },

    // matchListing End
    REFRESH: (state, data) => {
      state.refresh = !state.refresh;
    },
    IS_REDIRECT: (state, data) => {
      state.isRedirect = data;
    },
    COMPONENT_KEY: (state, data) => {
      state.COMPONENT_KEY = !state.refresh;
    },

    CURRENT_LOCALE_CHANGE_EVENT: (state, data) => {
      let displayLocale;
      let currentLanguageObj;

      if (data === "vn") {
        data = "vi";
      }

      config.languageList.forEach((x) => {
        if (x.locale === data) {
          x.selected = true;
          currentLanguageObj = x;
          displayLocale = x.displayLocale;
        } else {
          x.selected = false;
        }
      });
      if (typeof window !== "undefined") {
        let fullPath = window.location.pathname.split("/");
        fullPath[1] = displayLocale;

        router.push(fullPath.join("/") + window.location.search);
      }

      state.currentLanguageObj = currentLanguageObj;
      state.currentLanguageList = config.languageList;
      state.currentLocale = displayLocale;
    },
    HOME_PAGE_LEAGUE_PARAMS_CHANGE_EVENT: (state, data) => {
      state.homePageLeagueParams = data;
    },
    DEVICE_CHANGE_SIZE_EVENT: (state, data) => {
      state.isMobile = data;
    },
    POPUP_SIGN_IN_MODULE_EVENT: (state, data) => {
      state.popUpSignInModule = data;
    },
    TOASTR_CHANGE_EVENT: (state, data) => {
      state.toastr.isOpen = data.isOpen;
      state.toastr.type = data.type;
      state.toastr.msg = data.msg;
    },
    IS_SHOW_HEADER: (state, data) => {
      state.isShowHeader = data;
    },
    IS_SHOW_APP_DOWNLOAD_HOMEPAGE: (state, data) => {
      state.isShowAppDownloadHome = data;
    },
    IS_LOGIN: (state, data) => {
      state.isLogin = true;
      let userInfo = JSON.parse(localStorage.getItem(USER));
      state.userInfo = userInfo;
    },
    LOGOUT: (state, data) => {
      state.isLogin = false;

      localStorage.removeItem(USER);

      state.userInfo = {};
    },
    SET_USER_DATA: (state, data) => {
      if (data === null) {
        state.isLogin = false;
        localStorage.removeItem(USER);

        state.userInfo = {};

        window.location.href = `${window.location.protocol}//${
          window.location.host
        }/${location.pathname.split("/")[1]}`;
      } else {
        localStorage.setItem(USER, JSON.stringify(data));
        state.userInfo = data;

        state.isLogin = true;
      }
    },

    SUCUESS_GET_MATCH_LIST(state, data) {
      state.matchList = data.result.leagueMatchList;
    },
    MATCH_ID: (state, data) => {
      state.matchId = data;
    },

    SUCUESS_GET_MATCH_LIST(state, data) {
      state.matchList = data.result.leagueMatchList;
    },

    SUCUESS_GET_MATCH_DETAIL_SCORE(state, data) {
      if (data.awayTeamScore == "") {
        data.awayTeamScore = "-";
      }
      if (data.homeTeamScore == "") {
        data.homeTeamScore = "-";
      }
      let blackList = [
        "Aban.",
        "AET",
        "Cancelled",
        "Cancl.",
        "FT",
        "Pen.",
        "Postp.",
        "TBA",
      ];

      if (blackList.indexOf(data.matchStatus) != -1) {
        state.isStopTimmer = true;
      } else {
        state.isStopTimmer = false;
      }
      state.matchDetailScore = data;
    },
    SUCUESS_GET_MATCH_DETAIL_INFO(state, data) {
      state.matchDetailInfo = data;
    },
    SUCUESS_GET_TEAM_INFO(state, data) {
      let team = data.teams.team;
      state.teamInfo = team;

      let squad = team.squad.player; // filter naming for position
      let position;

      // for (let i = 0; i<squad.length; i++) {
      // 	switch (squad[i].pos) {
      // 		case 'A':
      // 			position = "ATTACKERS";
      // 		break;
      // 		case 'D':
      // 			position = "DEFENDERS";
      // 		break;
      // 		case 'G':
      // 			position = "GOAL_KEEPERS";
      // 		break;
      // 		case 'M':
      // 			position = "MIDFIELDERS";
      // 		break;
      // 	}
      // 	squad[i].position = position;
      // }

      if (team.trophies === null) {
        state.teamInfo.honours = null;
      } else {
        state.teamInfo.honours = team.trophies.trophy;
      }

      state.teamInfo.squadData = squad;

      state.teamInfo.sidelinedData =
        team.sidelined === null ? null : team.sidelined.player;

      state.teamInfo.transferInData = team.transfers.in.player;
      state.teamInfo.transferOutData = team.transfers.out.player;
    },

    SUCUESS_GET_PLAYER_INFO(state, data) {
      let playerInfo = data.players.player;
      state.playerInfo = playerInfo;

      if (data === null) {
        state.playerInfo.isShowData = false;
        return;
      } else {
        state.playerInfo.isShowData = true;
      }

      if (playerInfo.transfers === null) {
        state.playerInfo.transferData = null;
      } else {
        state.playerInfo.transferData = playerInfo.transfers.transfer;
      }

      if (playerInfo.trophies === null) {
        state.playerInfo.honours = null;
      } else {
        state.playerInfo.honours = playerInfo.trophies.trophy;
      }

      if (playerInfo.statistic === null) {
        state.playerInfo.statisticData = null;
      } else {
        state.playerInfo.statisticData = playerInfo.statistic.club;
      }
    },

    COMMENT_LIST_EVENT(state, data) {
      state.commentListInfo.totalComments = data.totalComments;
      // init value
      data.comments.forEach((x) => {
        x.isShowReply = false;
        x.isShowChildComment = false;
        x.isShowChildCommentReply = false;
      });

      if (data.comments.isMore) {
        data.comments.forEach((x) => {
          state.commentList.push(x);
        });
      } else {
        state.commentList = data.comments;
        state.firstCommentList = state.commentList[0];
      }
    },

    COMMENT_LIST_CHILD_EVENT(state, data) {
      state.commentList.forEach((x) => {
        if (x.newsCommentId === data.newsCommentParentId) {
          if (data.isShowPreviousComment) {
            for (let i = 0; i < data.comments.length; i++) {
              x.child.unshift(data.comments[data.comments.length - 1 - i]);
            }
            // data.comments.forEach(element => {
            // 	x.child.unshift(element)
            // });
            x.childHasNext = false;
          } else {
            x.child = {};

            x.child = data.comments;

            if (data.hasNext) {
              x.childHasNext = data.hasNext;
            }
          }
        }
      });
    },

    COMMENT_LIST_PUSH_EVENT(state, data) {
      data.comments.forEach((x) => {
        x.isShowReply = false;
        x.isShowChildComment = false;
        x.isShowChildCommentReply = false;
      });

      let latestComment = data.comments[0];

      if (typeof data.parentData !== "undefined") {
        //add new comment list to child
        for (let i = 0; i < state.commentList.length; i++) {
          if (state.commentList[i].newsCommentId === data.newsCommentParentId) {
            state.commentList[i].childCommentListingRes.remainingCommentCount++;

            if (typeof state.commentList[i].child == "undefined") {
              state.commentList[i].child = [];
            }
            state.commentList[i].child.push(latestComment);
          }
        }
      } else {
        state.commentListInfo.totalComments = data.totalComments;
        //add new comment list to parent
        state.commentList.unshift(latestComment);
        state.firstCommentList = state.commentList[0];
      }
    },

    COMMENT_LIST_INFO(state, data) {
      state.commentListInfo.totalComments = data.totalComments;
    },

    NAVIGATION_MENU_EVENT(state, data) {
      let returnResult = data;
      let currentDateTime = new Date();
      let isEventParticipated;
      let finalEventParticipated = true;

      for (const x of returnResult) {
        //event haven't end
        if (x.endDate > currentDateTime.getTime()) {
          if (x.participated) {
            isEventParticipated = true;
          } else {
            isEventParticipated = false;
            finalEventParticipated = false;
          }
        } else {
          isEventParticipated = true;
        }
      }
      let headerTabs = state.headerTabs.filter((x) => x.id === 4)[0];
      let footerTabs = state.footerTabs.filter((x) => x.id === 4)[0];

      headerTabs.isLive = !finalEventParticipated;
      footerTabs.isLive = !finalEventParticipated;
    },
    COMMON_VERIFY_LOGIN(state, data) {
      let localStorageUser = localStorage.getItem("user");

      //if local storage has user data mean status is Login
      if (localStorageUser !== null) {
        if (localStorageUser !== "null") {
          this._actions.isLoginEvent[0]();
        }
      }
    },
    CHECK_IF_IS_WORLD_CUP(state, data) {
      state.isWorldCup = data;
    },
    CHECK_IF_IS_UEFA(state, data) {
      state.isUefa = data
    },
    CHECK_LISTING_IF_GOT_LIVE_STREAM(state, data) {
      state.listingHasLiveStream = data;
    },
    SET_DEFAULT_VIDEO_TO_PLAY(state, data) {
      state.defaultVideoToPlay = data;
    },
    SET_MATCH_LIST_DATA_INTERVAL(state, data) {
      state.matchListDataInterval = data;
    },
  },

  getters: {
    // matchListing
    isOddsMenu: (state) => state.isOddsMenu,
    isOddsMore: (state) => state.isOddsMore,
    homeOdds: (state) => state.homeOdds,
    awayOdds: (state) => state.awayOdds,
    h2hOdds: (state) => state.h2hOdds,
    // matchListing End

    refresh: (state) => state.refresh,
    isRedirect: (state) => state.isRedirect,
    componentKey: (state) => state.componentKey,

    matchList2: (state) => state.matchList,
    homePageLeagueParams: (state) => state.homePageLeagueParams,
    matchDetailScore: (state) => state.matchDetailScore,
    matchDetailInfo: (state) => state.matchDetailInfo,
    isStopTimmer: (state) => state.isStopTimmer,
    matchId: (state) => state.matchId,

    currentSportType: (state) => state.currentSportType,
    currentLocale: (state) => state.currentLocale,
    currentLanguageObj: (state) => state.currentLanguageObj,
    currentLanguageList: (state) => state.currentLanguageList,

    isMobile: (state) => state.isMobile,
    isShowHeader: (state) => state.isShowHeader,
    isShowAppDownloadHome: (state) => state.isShowAppDownloadHome,

    isLogin: (state) => state.isLogin,
    userInfo: (state) => state.userInfo,
    userRegistrationDate: (state) =>
      moment(state.userInfo.registrationDate, [
        "YYYY-MM-DD",
        "DD-MM-YYYY",
      ]).format("D/M/YYYY"),

    teamInfo: (state) => state.teamInfo,
    playerInfo: (state) => state.playerInfo,
    footerTabs: (state) => state.footerTabs,
    headerTabs: (state) => state.headerTabs,
    iframeHeaderTabs: (state) => state.iframeHeaderTabs,
    eplHeaderTabs: (state) => state.eplHeaderTabs,
    eplFooterTabs: (state) => state.eplFooterTabs,

    commentList: (state) => state.commentList,
    commentListInfo: (state) => state.commentListInfo,
    firstCommentList: (state) => state.firstCommentList,

    popUpSignInModule: (state) => state.popUpSignInModule,
    toastr: (state) => state.toastr,
    isWorldCup: (state) => state.isWorldCup,
    isUefa: (state) => state.isUefa,
    listingHasLiveStream: (state) => state.listingHasLiveStream,
    defaultVideoToPlay: (state) => state.defaultVideoToPlay,
    matchListDataInterval: (state) => state.matchListDataInterval,
  },

  actions: {
    // matchListing
    isOddsMenuToggle({ commit }, params) {
      commit("MATCHODDSMENU", params);
    },
    isOddsMoreToggle({ commit }, params) {
      commit("MATCHODDSMORE", params);
    },
    oddsOptionChangeEvent({ commit }, params) {
      commit("MATCHODDS_OPTION_HANDLER", params);
    },
    isSameToggle({ commit }, params) {
      commit("MATCHODDS_ISSAME_HANDLER", params);
    },
    oddsLeagueListHandler({ commit }, params) {
      commit("MATCHODDS_LEAGUE_HANDLER", params);
    },
    // matchListing End
    refresh({ commit }, params) {
      commit("REFRESH", params);
    },
    isRedirect({ commit }, params) {
      commit("IS_REDIRECT", params);
    },
    componentKey({ commit }, params) {
      commit("COMPONENT_KEY", params);
    },
    homePageLeaugeParamsChangeEvent({ commit }, params) {
      commit("HOME_PAGE_LEAGUE_PARAMS_CHANGE_EVENT", params);
    },
    isShowHeaderEvent({ commit }, params) {
      commit("IS_SHOW_HEADER", params);
    },
    isShowAppDownloadHomePageEvent({ commit }, params) {
      commit("IS_SHOW_APP_DOWNLOAD_HOMEPAGE", params);
    },
    isLoginEvent({ commit }, params) {
      commit("IS_LOGIN", params);
    },
    commonVerifyLogin({ commit }, params) {
      commit("COMMON_VERIFY_LOGIN", params);
    },
    logoutEvent({ commit }, params) {
      commit("LOGOUT", params);
    },
    navigationMenuEvent({ commit }, params) {
      commit("NAVIGATION_MENU_EVENT", params);
    },
    setUserData({ commit }, params) {
      commit("SET_USER_DATA", params);
    },
    currentLocaleChangeEvent({ commit }, params) {
      commit("CURRENT_LOCALE_CHANGE_EVENT", params);
    },
    deviceChangeSizeEvent({ commit }, params) {
      commit("DEVICE_CHANGE_SIZE_EVENT", params);
    },
    popUpSignInModuleEvent({ commit }, params) {
      commit("POPUP_SIGN_IN_MODULE_EVENT", params);
    },
    toastrChangeEvent({ commit }, params) {
      commit("TOASTR_CHANGE_EVENT", params);
    },
    //comment
    commentListEvent({ commit }, params) {
      commit("COMMENT_LIST_EVENT", params);
    },

    commentListChildEvent({ commit }, params) {
      commit("COMMENT_LIST_CHILD_EVENT", params);
    },
    commentListPushEvent({ commit }, params) {
      commit("COMMENT_LIST_PUSH_EVENT", params);
    },

    commentListInfoEvent({ commit }, params) {
      commit("COMMENT_LIST_INFO", params);
    },

    checkIfIsWorldCup({ commit }, params) {
      commit("CHECK_IF_IS_WORLD_CUP", params)
    },

    checkIfIsUefa({ commit }, params) {
      commit("CHECK_IF_IS_UEFA", params)
    },

    getLeagueList({ commit }, params) {
      if (typeof params === "undefined") {
        params = {};
      }
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/soccerLeague/leagueList`, params);
    },
    getMatchList({ commit }, params) {
      if (typeof params === "undefined") {
        params = {};
      }
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      api
        .get(`/${API_VERSION}/match/getMatchList`, params)
        .then((data) => {
          commit("SUCUESS_GET_MATCH_LIST", data);
        })
        .catch((res) => {
          return "";
        });
    },

    getH2hInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/h2h/info`, params);
    },
    getMatchDetailScore({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      commit("MATCH_ID", params.matchId);
      return api
        .get(`/${API_VERSION}/match/score`, params)
        .then((data) => {
          commit("SUCUESS_GET_MATCH_DETAIL_SCORE", data.result);
        })
        .catch((res) => {
          return "";
        });
    },
    getMatchDetailScoreAsync({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/match/score`, params);
    },
    getFixtureByLeague({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/match/getFixtureByLeague`, params);
    },
    getMatchListAsync({ commit }, params) {
      return api.get(`/${API_VERSION}/match/getMatchList`, params);
      //return api.get(`/${API_VERSION}/match/getMatchList`, params);
    },
    getMatchListAsync2({ commit }, params) {
      //params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/match/getMatchListv2`, params);
    },
    getMatchListv2WCIframe({ commit }, params) {
      // params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/match/getMatchListv2WC_iframe`, params);
    },
    getMatchListEPL({ commit }, params) {
      return api.get(`/${API_VERSION}/match/getMatchListEPL`, params);
    },
    getMatchListUEFA({ commit }, params) {
      return api.get(`/${API_VERSION}/match/uefa_euro_2024/getMatchListv4_iframe`, params);
    },
    getMatchListByTeam({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/match/getMatchListByTeam`, params);
    },

    //start match detail api
    getMatchDetailScoreAsync({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/match/score`, params);
    },
    getMatchDetaiHighlightList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/match/getHighlightList`, params);
    },
    getMatchDetaiInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/match/info`, params);
    },
    getMatchDetaiEvent({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/match/event`, params);
    },
    getMatchDetailLiveTrackerUrl({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/match/getLiveTrackerUrl`, params);
    },
    getMatchDetailCommentary({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/match/commentary`, params);
    },
    getMatchDetailStat({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/match/stat`, params);
    },
    getMatchDetailLineUp({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/match/lineup`, params);
    },
    // end match detail api

    getLeagueOdds({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      if (params.side === "h2h") {
        return api.get(`/${API_VERSION}/h2h/odds`, params.matchId);
      } else {
        return api.get(`/${API_VERSION}/last/${params.side}`, params.matchId);
      }
    },
    getGoalScoringStats({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/match/stat/goal`, params);
    },
    getSquadStatus({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/match/analysis/getSquadStatus`, params);
    },
    getPerformance({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/player/getPerformance`, params);
    },
    getLeagueStanding({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(
        `/${API_VERSION}/soccer/getLeagueStandingStatByMatch`,
        params
      );
    },
    getNextFiveMatch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/match/nextFiveMatch`, params);
    },
    // end match listing api

    getSoccerLeagueRanking({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/soccerLeague/ranking`, params);
    },
    getSoccerSubLeagueList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/soccerLeague/subLeagueList`, params);
    },
    getSeasonListByLeagueId({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/match/getSeasonListByLeagueId`, params);
    },

    getTeamInfo({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      params.token = this.getters.userInfo.token;
      return api
        .get(`/${API_VERSION}/team/info`, params)
        .then((data) => {
          commit("SUCUESS_GET_TEAM_INFO", data.result);
        })
        .catch((res) => {
          return "";
        });
    },
    getPlayerInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api
        .get(`/${API_VERSION}/player/info`, params)
        .then((data) => {
          commit("SUCUESS_GET_PLAYER_INFO", data.result);
        })
        .catch((res) => {
          return "";
        });
    },

    getHighlightList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/highlight/highlightList`, params);
    },

    getDateListEpl({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get(`/${API_VERSION}/match/dateListEPL`, params);
    },

    getNewsLetterSubscribe({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get("/newsLetter/subscribe", params);
    },

    getFocusPictureFindPicture({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/focusPicture/findPicture`, params);
    },

    //news
    getAllNewsList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/news/getAllNewsList`, params);
    },
    getNewsDetail({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/news/getNewsDetail`, params);
    },
    getNewsListByAuthor({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/news/getNewsListByAuthor`, params);
    },
    getNewsListByCategory({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/news/getNewsListByCategory`, params);
    },
    getNewsListByTag({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/news/getNewsListByTag`, params);
    },

    //signup
    getWSUP({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post(`/user/${API_VERSION}/getWSUP`, params);
    },
    postSignup({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/signup", params);
    },
    postUpdateReferralCode({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/updateReferralCode", params);
    },

    getLoginMethod({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get("/user/getLoginMethod", params);
    },

    //sign in
    postSignin({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/login", params);
    },

    postSigninLine({ commit }, params) {
      return api.post("/user/loginLine", params);
    },
    postSigninZalo({ commit }, params) {
      return api.post("/user/loginZalo", params);
    },
    getWLGP({ commit }, params) {
      return api.post(`/user/${API_VERSION}/getWLGP`, params);
    },
    postLoginOtp({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/loginOtp", params);
    },

    //Logout
    postLogout({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/logout", params);
    },

    //forget password
    postForgotPasswordGetCode({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/forgotPassword/getCode", params);
    },

    //forget password validate
    postForgotPasswordValidate({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/forgotPassword/validate", params);
    },

    //change password
    postChangePassword({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/forgotPassword/changePassword", params);
    },

    //set password
    postSetPassword({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/setPassword", params);
    },

    //get current location info
    getCurrentLocation({ commit }, params) {
      return api.getSelfUrl("http://ip-api.com/json", params);
    },

    //profile
    getRetrieveUserInfo({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      return api.get("/user/retrieveUserInfo", params);
    },
    postUpdateProfilePicture({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/updateProfilePicture", params);
    },
    postUpdateProfile({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/updateProfile", params);
    },
    postUpdatePassword({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/updatePassword", params);
    },
    postUpdateProfilePreferredLanguage({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.locale;
      return api.post("/user/updateProfilePreferredLanguage", params);
    },

    //comment
    postNewsAddComment({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post(`/${API_VERSION}/news/addComment`, params);
    },
    getNewsListComment({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/${API_VERSION}/news/listComment`, params);
    },
    postNewsLikeComment({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post(`/${API_VERSION}/news/likeComment`, params);
    },

    //event prediction
    getContentList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get("/contest/getContestList", params);
    },
    getContestInfo({ commit }, params) {
      return api.get("/contest/getContestInfo", params);
    },
    getTeamListByContestId({ commit }, params) {
      return api.get("/contest/getTeamListByContestId", params);
    },
    getContestJoin({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      params.token = this.getters.userInfo.token;
      return api.get("/contest/join", params);
    },
    getContesterPredictionList({ commit }, params) {
      return api.get("/contest/getContesterPredictionList", params);
    },
    getMyPredictionList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get("/contest/getMyPredictionList", params);
    },
    getLatestContesterPredictionList({ commit }, params) {
      return api.get("/contest/getLatestContesterPredictionList", params);
    },

    //event referreal
    postParticipate({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.locale;
      params.token = this.getters.userInfo.token;
      return api.post("/contest2/participate", params);
    },
    postListParticipate({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post("/contest2/listParticipant", params);
    },
    postMyParticipate({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post("/contest2/myParticipant", params);
    },

    //event Workcup Knockout
    getKnockout({ commit }, params) {
      return api.get(`/${API_VERSION}/contest4/knockout`, params);
    },
    // event UEFA Knockout
    getUefaKnockout({ commit }, params) {
      return api.get(`/${API_VERSION}/match/uefa_euro_2024/knockout`, params);
    },
    
  },
  modules: {},
});
