<template>
    <div class="display-flex-center">
        <img src="../../../static/images/loading.gif">
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>