<template>
  <!-- <APPDownloadHomePage v-show="isShowHeader && isShowAppDownloadHome"></APPDownloadHomePage> -->
  <div class="home-nav__match-wrapper" :class="{'home-nav__match-wrapper__app-download':isShowAppDownloadHome}" v-show="isShowHeader">
    <div class="container" style="overflow:visible">
      <div class="display-flex-sb ">
        <div class="display-flex-center">
          <!-- <div class="home-nav__icon-wrapper">
            <MainLogo class="home-logo"></MainLogo>
          </div> -->
          <div class="home-nav__container">
            <router-link
              :to="{ name: o.pathName }"
              class="home-nav__child relative"
              :class="{
                selected: o.selected,
                'font-l': currentLocale === 'th',
              }"
              v-for="(o, index) in tabsObj"
              :key="index"
            >
              <span>{{ $t(o.name) }}</span>
              <div class="home-nav__child-indicator"></div>
              <!-- <div v-if="o.isLive" class="dot-wrapper">
                <div class="ripple"></div>
              </div> -->
            </router-link>
            <!-- <router-link :to="`/${currentLocale}/highlights`" class="home-nav__child"><span>Highlights</span><div class="home-nav__child-indicator"></div></router-link> -->
          </div>
        </div>
        <div class="display-flex-center" v-if="!isWorldCup && false">
          <!-- <Setting></Setting> -->
          <!-- Language Selection -->
          <div
            v-if="!isLogin"
            class="language-wrapper"
            @mouseenter="handleShowLanguageList('enter')"
            @mouseleave="handleShowLanguageList('leave')"
          >
            <div class="langauge-selection__wrapper">
              <div
                class="langauge-selection"
                @click="handleShowLanguageList('click')"
              >
                <img
                  class="img-contain"
                  :src="
                    require(`../../../static/images/flags/${currentLanguageObj.icon}.png`)
                  "
                />
              </div>
            </div>
            <div
              class="language-list__wrapper"
              :class="{ selected: isShowLanguageList }"
            >
              <div
                class="language-list__container"
                v-for="(o, index) in currentLanguageList"
                :key="index"
                @click="handleLanguage(o)"
              >
                <div class="language-list__name">
                  <img
                    class="img-contain language-list__name-icon"
                    :src="require(`../../../static/images/flags/${o.icon}.png`)"
                  /><span>{{ o.displayName }}</span>
                </div>
                <div
                  class="language-list__tick"
                  :class="{ selected: o.selected }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Setting from "@/components/header/Setting.vue";
// import MainLogo from "@/components/indicator/MainLogo.vue";

// import APPDownloadHomePage from '@/components/mobile/app/APPDownloadHomePage.vue'
import config from '@/js/config.js'
import {mapGetters,mapActions} from 'vuex'
export default {
	components:{
		// Setting,
    // MainLogo,
    // APPDownloadHomePage
	},
    data() {
        return {
          isShowLanguageList : false,
          tabsObj: {},
        }
    },
  computed: {
    ...mapGetters([
      "isShowHeader",
      "isShowAppDownloadHome",
      "currentLocale",
      "currentLanguageList",
      "currentLanguageObj",
      "headerTabs",
      "iframeHeaderTabs",
      "isMobile",
      "isLogin",
      "userInfo",
      "isWorldCup",
    ]),
  },
  watch: {
    $route() {
      //this.initLangauge();
    },
  },
  mounted() {
    //this.initLangauge();
    this.tabsObj = this.$route.name === "home" ? this.headerTabs : this.iframeHeaderTabs;
  },
  methods: {
    ...mapActions([
      "currentLocaleChangeEvent",
      "postUpdateProfilePreferredLanguage",
      "getRetrieveUserInfo",
    ]),

    handleShowLanguageList(action) {
      if (action === "enter" && !this.isMobile) {
        //ingnore mobile touch event
        this.isShowLanguageList = true;
      } else if (action === "click") {
        this.isShowLanguageList = !this.isShowLanguageList;
      } else {
        this.isShowLanguageList = false;
      }
    },

    handleLanguage(o) {
      this.currentLocaleChangeEvent(o.locale);
    },
  },
};
</script>

<style scoped>
.language-wrapper {
  position: relative;
}
.langauge-selection__wrapper {
  width: 3.5rem;
  display: flex;
  justify-content: flex-end;
}
.langauge-selection {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey-60);
  cursor: pointer;
}
.langauge-selection:hover {
  background-color: var(--color-grey-40);
}
.language-list__wrapper {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.1rem;
  min-width: 12rem;
  background-color: var(--color-grey-60);
  padding: 0.5rem 0.3rem;
  border-radius: 0.5rem;
  transform: scale(0);
  transform-origin: 11.5rem 0%;
  transition: all 0.3s;
}
.language-list__wrapper.selected {
  /* transform-origin: 11.5rem 0%; */
  transform: scale(1);
}
.language-list__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  transition: all 0.3s;
}
.language-list__container:hover {
  background-color: var(--color-grey-40);
  border-radius: 0.4rem;
}
.language-list__name {
  display: flex;
  align-items: center;
  font-size: 0.88rem;
}
.language-list__name-icon {
  object-fit: contain;
  margin-right: 0.7rem;
  width: 1.5rem;
  height: 1.5rem;
}
.language-list__tick {
  width: 1rem;
  height: 1rem;
}
.language-list__tick.selected {
  background: url("../../../static/images/icons/icon_tick_selected.png")
    center/cover no-repeat;
}
.language-list__tick img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .language-wrapper {
    right: 1rem;
  }
}
</style>
