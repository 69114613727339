<template>
  <div class="footer-wrapper">
    <div class="footer-wrapper__copy">
      &copy; 2023 WATCHEPL. {{ $t("ALL_RIGHTS_RESERVED") }}
    </div>
    <div class="footer-wrapper__language-selection">
      <div class="language-list__wrapper">
        <div
          class="language-list__container"
          v-for="(o, index) in currentLanguageList"
          :key="index"
          :class="{ selected: o.selected }"
          @click="handleLanguage(o)"
        >
          <div class="language-list__name" :class="{ selected: o.selected }">
            <img
              class="img-contain language-list__name-icon"
              :src="require(`../../../static/images/flags/${o.icon}.png`)"
            /><span>{{ o.displayName }}</span>
          </div>
          <!-- <div
            class="language-list__tick"
            :class="{ selected: o.selected }"
          ></div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="sticky-footer-wrapper" v-if="isMobile">
    <ul class="footer-container">
      <a
        class="footer-icon__wrapper"
        :class="{ selected: o.selected }"
        v-for="(o, index) in eplFooterTabs"
        :key="index"
        :href="o.external ? o.link : '/' + currentLocale"
        :target="o.external ? '_blank' : '_self'"
      >
        <div :class="`footer-icon__container footer-icon__${o.id}`"></div>
        <span>{{ $t(o.name) }}</span>
      </a>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentLanguageList", "isMobile", "eplFooterTabs", "currentLocale"]),
  },
  methods: {
    ...mapActions(["currentLocaleChangeEvent"]),
    handleLanguage(o) {
      this.currentLocaleChangeEvent(o.locale);
    },
  },
};
</script>

<style scoped>
.footer-wrapper {
  margin: 6rem auto 2rem;/*3.375rem auto 2rem*/
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-wrapper__copy {
  padding: 0.813rem 0;
  font-size: 0.875rem;
  font-weight: 700;
}
.footer-wrapper__language-selection {
  margin: 0.813rem 0;
}
.language-list__wrapper {
  display: flex;
}
.language-list__name.selected {
  color: var(--color-theme-2);
}
.language-list__container {
  padding: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.language-list__name {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
}
.language-list__name-icon {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .footer-wrapper {
    margin: 3.375rem auto 3.938rem;
  }
  .footer-wrapper__copy {
    padding: 0.25rem 0;
  }
  .footer-wrapper__language-selection {
    margin: 0.25rem 0.5rem;
  }
  .sticky-footer-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #35454d;
    background: #000000;
  }
  .sticky-footer-wrapper .footer-container {
    display: flex;
    margin: 0.5rem 0 0.8rem 0;
    display: flex;
    justify-content: center;
  }

  .footer-icon__wrapper {
    -webkit-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    color: gray;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .footer-icon__container {
    display: block;
    margin: 0 auto;
    width: 1.3rem;
    height: 1.3rem;
    transition: all.5s;
    margin-bottom: 0.2rem;
  }
  .footer-icon__wrapper.selected {
    color: var(--color-theme-light);
  }
  /* .footer-icon__1 {
    background: url(../../../static/images/icons/icon_footer_home_unselect.png)
      center/contain no-repeat;
    width: 15px;
  } */
  .selected .footer-icon__1 {
    animation: nimateBall 0.5s ease forwards;
    -webkit-animation: nimateBall 0.5s ease forwards;
    background: url(../../../static/images/icons/icon_footer_home_selected.png)
      center/contain no-repeat;
    width: 15px;
  }
  .footer-icon__2 {
    background: url(../../../static/images/icons/icon_footer_highlights_unselect.png)
      center/contain no-repeat;
  }
  /* .selected .footer-icon__2 {
    animation: nimateBall 0.5s ease forwards;
    -webkit-animation: nimateBall 0.5s ease forwards;
    background: url(../../../static/images/icons/icon_footer_highlights_selected.png)
      center/contain no-repeat;
  } */
  .footer-icon__3 {
    background: url(../../../static/images/icons/icon_footer_news_unselect.png)
      center/contain no-repeat;
  }
  /* .selected .footer-icon__3 {
    animation: nimateBall 0.5s ease forwards;
    -webkit-animation: nimateBall 0.5s ease forwards;
    background: url(../../../static/images/icons/icon_footer_news_selected.png)
      center/contain no-repeat;
  } */
  .footer-icon__4 {
    background: url(../../../static/images/icons/icon_footer_fantasy_unselect.png)
      center/contain no-repeat;
  }
  /* .selected .footer-icon__4 {
    animation: nimateBall 0.5s ease forwards;
    -webkit-animation: nimateBall 0.5s ease forwards;
    background: url(../../../static/images/icons/icon_footer_event_selected.png)
      center/contain no-repeat;
  } */
}
</style>
