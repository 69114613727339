<template>
  
    <div class="no-data">{{$t('NO_DATA')}}</div>

</template>

<script>
export default {

}
</script>

<style>

</style>