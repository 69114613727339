<template>
  <router-view/>
  <Toastr ref="toastrRef" :text="toastrMsg" :type="toastrType" :position="toastrPosition"></Toastr> 
</template>


<script>

  import ResizeMixin from '@/js/mixin/handleResizeAndRouting.js'
  import Toastr from '@/components/toastr/Toastr.vue'

  export default {
    components:{
      Toastr
    },
      
    data(){
      return{
          isShowHeader:true,
          toastrMsg:'',
          isHomePage: null
      }
    },
    mixins: [ResizeMixin],
  }
</script>

<style>

  @import url('https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.5.8/swiper-bundle.css');
  @import url('https://cdn.jsdelivr.net/npm/mc-datepicker/dist/mc-calendar.min.css');

  /* @import '../src/js/swipper.js'; */

#app {
  /* font-family: 'Open Sans', Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
</style>
